.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - var(--navbar-height));
    background-color: var(--background-color);
  }
  
  .auth-box {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    position: relative;
  }
  
  .auth-title {
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 1.5rem;
    font-family: 'Cormorant Garamond', serif;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-color);
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--accent-color);
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .auth-button {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-button:hover {
    background-color: var(--secondary-color);
  }
  
  .auth-switch {
    text-align: center;
    margin-top: 1rem;
  }
  
  .switch-button {
    background: none;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
    font-weight: bold;
  }
  
  .switch-button:hover {
    text-decoration: underline;
  }
  
  .gsi-material-button {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: WHITE;
    background-image: none;
    border: 1px solid #747775;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: 'Lato', arial, sans-serif;
    font-size: 14px;
    height: 48px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
    transition: background-color .218s, border-color .218s, box-shadow .218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    /* max-width: 400px; */
    min-width: min-content;
  }
  
  .gsi-material-button .gsi-material-button-icon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }
  
  .gsi-material-button .gsi-material-button-content-wrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .gsi-material-button .gsi-material-button-contents {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: 'Lato', arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  
  .gsi-material-button .gsi-material-button-state {
    -webkit-transition: opacity .218s;
    transition: opacity .218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .gsi-material-button:disabled {
    cursor: default;
    background-color: #ffffff61;
    border-color: #1f1f1f1f;
  }
  
  .gsi-material-button:disabled .gsi-material-button-contents {
    opacity: 38%;
  }
  
  .gsi-material-button:disabled .gsi-material-button-icon {
    opacity: 38%;
  }
  
  .gsi-material-button:not(:disabled):active .gsi-material-button-state, 
  .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
    background-color: #303030;
    opacity: 12%;
  }
  
  .gsi-material-button:not(:disabled):hover {
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  }
  
  .gsi-material-button:not(:disabled):hover .gsi-material-button-state {
    background-color: #303030;
    opacity: 8%;
  }
  
  .auth-modal .modal-content {
    background-color: white;
    border-radius: 12px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .auth-modal .auth-container {
    min-height: unset;
    padding: 0;
    background-color: transparent;
  }
  
  .auth-modal .auth-box {
    box-shadow: none;
    padding: 1rem;
    background-color: transparent;
    max-width: 100%;
  }
  
  .auth-modal .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  .auth-modal .modal-body {
    padding-top: 0;
  }
  
  .auth-modal .auth-title {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  .success-message {
    color: #28a745;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .auth-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .error-message {
    color: #dc3545;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .password-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .password-toggle {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    color: var(--text-color);
    cursor: pointer;
    padding: 5px;
  }
  
  .password-strength-indicator {
    margin-top: 0.5rem;
  }
  
  .strength-bar {
    height: 4px;
    background-color: #e9ecef;
    border-radius: 2px;
    margin-bottom: 0.25rem;
  }
  
  .strength-bar.weak .strength-fill {
    width: 33%;
    background-color: #dc3545;
  }
  
  .strength-bar.medium .strength-fill {
    width: 66%;
    background-color: #ffc107;
  }
  
  .strength-bar.strong .strength-fill {
    width: 100%;
    background-color: #28a745;
  }
  
  .password-requirements {
    font-size: 0.875rem;
    color: #6c757d;
    margin-top: 0.5rem;
    padding-left: 1.25rem;
  }
  
  .auth-modal .auth-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  .auth-modal .auth-tabs {
    border-bottom: none;
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 0 1rem;
  }
  
  .auth-tab {
    color: var(--primary-color) !important;
    border: none !important;
    border-radius: 4px !important;
    padding: 0.75rem 1.5rem !important;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .auth-tab:hover {
    background-color: var(--accent-color) !important;
    color: var(--primary-color) !important;
  }
  
  .auth-tab.active {
    background-color: var(--accent-color) !important;
    color: var(--primary-color) !important;
  }
  
