:root {
  --bs-primary: #3a5a78;
  --bs-secondary: #7fa1c0;
  --bs-success: #28a745;
  --bs-info: #17a2b8;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #343a40;
  --bs-font-sans-serif: 'Lato', sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

body {
  font-family: var(--bs-font-sans-serif);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Cormorant Garamond', serif;
  color: var(--bs-primary);
}

.card-title {
  color: var(--bs-primary) !important;
}

.btn-primary,
.btn-outline-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary.show,
.btn-primary:first-child:active,
:not(.btn-check) + .btn-primary:active {
  font-family: var(--bs-font-sans-serif);
}

.btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary.show,
.btn-primary:first-child:active,
:not(.btn-check) + .btn-primary:active {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: white;
}

.btn-primary:hover:not(:disabled),
.btn-primary:focus:not(:disabled),
.btn-primary:active:not(:disabled) {
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
}

.btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.show,
.btn-outline-primary:first-child:active,
:not(.btn-check) + .btn-outline-primary:active {
  color: var(--bs-primary);
  border-color: var(--bs-primary);
  background-color: transparent;
}

.btn-outline-primary:hover:not(:disabled),
.btn-outline-primary:focus:not(:disabled),
.btn-outline-primary:active:not(:disabled) {
  background-color: var(--bs-primary);
  color: white;
}

/* Disabled button styles */
.btn-primary:disabled,
.btn-primary.disabled,
.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: white;
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: var(--bs-primary);
  background-color: transparent;
}

/* Ensure disabled buttons don't change on hover */
.btn-primary:disabled:hover,
.btn-primary.disabled:hover,
.btn-outline-primary:disabled:hover,
.btn-outline-primary.disabled:hover {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: white;
}

.btn-outline-primary:disabled:hover,
.btn-outline-primary.disabled:hover {
  background-color: transparent;
  color: var(--bs-primary);
}

.btn {
  transition: all 0.3s ease;
}
