.navbar {
  background-color: var(--background-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1100;
  height: var(--navbar-height);
}

.logo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
}

.brand {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 600;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  color: var(--primary-color);
  text-decoration: none;
}

.brand:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.link {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.6rem;
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
  border-bottom: 2px solid transparent;
  color: var(--text-color);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
}

.link:hover {
  color: var(--primary-color);
  background-color: transparent;
}

.active {
  color: var(--primary-color);
  border-bottom-color: var(--primary-color);
  font-weight: 500;
}

.icon {
  margin-right: 0.5rem;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.link:hover .icon,
.active .icon {
  opacity: 1;
}

.linkText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 991px) {
  .navList {
    padding-top: 1rem;
  }

  .link {
    padding: 0.75rem 1rem;
  }

  .active {
    border-bottom-color: transparent;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .collapse {
    position: absolute;
    top: var(--navbar-height);
    left: 0;
    right: 0;
    background-color: var(--background-color);
    padding: 1rem;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}
