/* General styles */
:root {
  --primary-color: #3a5a78;
  --secondary-color: #7fa1c0;
  --text-color: #2c3e50;
  --background-color: #f5f7fa;
  --accent-color: #e1eaf4;
  --highlight-color: #d4e6f1;
  --context-color: #fcf3e5;
  --navbar-height: 60px;
  --border-color: #e0e0e0;
  --background-color-light: #f9f9f9;
  --accent-color-light: #e1eaf4;
  --warning-color: #e67e22;
  --warning-color-light: #fdf2e9;
  --warning-color-dark: #d35400;
  --warning-text-color: #d35400;
  --warning-border-color: #f39c12;
}

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;600&family=Lato:wght@400;700&display=swap');

html, body {
  height: 100%;
}

body {
  font-family: 'Lato', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.8;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.main-content {
  padding-top: var(--navbar-height);
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

h1, h2, h3 {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 600;
  color: var(--primary-color);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: var(--secondary-color);
  text-decoration: none;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid var(--accent-color);
  border-top: 3px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ef9a9a;
  margin: 10px 0;
}

.error-message p {
  margin: 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.duration-select {
  display: inline-block;
  width: auto;
  min-width: 200px;
  padding: 0.5rem 2rem 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--primary-color);
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%23343a40' d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' transform='rotate(90 4 4)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid var(--secondary-color);
  border-radius: 0.25rem;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 1rem;
}

.duration-select:focus {
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.duration-select option {
  font-weight: normal;
}

.divider {
  margin: 2rem 0;
  border: 0;
  border-top: 1px solid var(--secondary-color);
}

.error-container {
  text-align: center;
  padding: 2rem;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  color: #721c24;
}

.error-message {
  font-weight: bold;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.25rem;
}

@media (max-width: 768px) {
  .main-content {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
